<template>
  <div class="d-flex full-height full-width flex-1">
    <dynamic-data-table
      :export-file-name="`${supplier.name}_${
        selectedElement ? `${selectedElement}_` : ''
      }objects`"
      :has-options="true"
      :is-loading="tableLoading"
      :table-headers="tableHeaders"
      :table-records="tableRecords"
      :table-title="`${supplier.name} Objects`"
      auto-sort-column="object_type"
      class="full-width"
    >
      <template
        v-for="tmp in tableHeaders"
        #[`header.${tmp.value}`]="{ header }"
      >
        <th :key="tmp.value">{{ tmp.text }} ({{ getHeaderTotal(tmp) }})</th>
      </template>
      <template
        v-for="tmp in tableHeaders"
        #[`item.${tmp.value}`]="{ value, rowId, item }"
      >
        <td
          :key="tmp.value"
          class="clickable-cell"
          @click="clickedCell(item[tmp.value], item.element_type, tmp.value)"
        >
          {{ value.count }}
        </td>
      </template>
      <template #item.check="{ value, rowId, item }">
        <div class="d-flex">
          <v-icon v-if="selectedElement" small class="mr-1">
            {{
              checkObjectTypeCount(item) % 1 != 0
                ? 'mdi-alert-circle'
                : 'mdi-check-circle'
            }}
          </v-icon>
          {{ selectedElement ? checkObjectTypeCount(item) : '-' }}
        </div>
      </template>
      <template #table-actions>
        <v-alert
          v-if="invalidData.length > 0"
          border="left"
          class="ma-0 mr-2"
          dense
          type="warning"
        >
          {{ text('incorrectObjectsDataSetWarning', invalidData.length) }}
        </v-alert>
        <v-autocomplete
          v-model="selectedElement"
          :items="elements"
          :placeholder="text('element')"
          clearable
          dense
          filled
          hide-details
          style="max-width: 200px"
        />
        <div v-if="selectedElement" class="mx-2">
          {{ elementCount }} {{ $t('general.numberOfAbbreviation') }}
        </div>
      </template>
      <template #table-options-menu>
        <v-list-item @click="exportSpecification">
          <v-list-item-icon style="margin-right: 10px">
            <v-icon dense> mdi-export </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            text('exportSpecification')
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="exportSpecificationPerElement">
          <v-list-item-icon style="margin-right: 10px">
            <v-icon dense> mdi-export </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            text('exportSpecificationPerElement')
          }}</v-list-item-title>
        </v-list-item>
      </template>
    </dynamic-data-table>
    <div
      v-if="selectedCell"
      class="ant-glass-background radius-0 bg-transparent ant-border-left ml-5 full-height pa-5 d-flex flex-column overflow-hidden"
      style="min-width: 400px"
    >
      <div class="d-flex align-center font-weight-bold" style="font-size: 18px">
        {{ $t('modules.BillOfMaterial.elementSchedule.quant') }}
        <v-spacer />
        <v-btn
          icon
          @click="
            selectedCell = undefined;
            detailViewerRendered = false;
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="mb-2">
        {{ selectedCell.header }} | {{ selectedCell.element_type }}
        <div />
      </div>
      <div class="flex-grow-1 overflow-y-auto">
        <div
          v-for="(module, index) in selectedCell?.modules"
          :key="index"
          class="px-2 py-1 d-flex align-center"
        >
          {{
            dhmeBillOfMaterialModules.find((item) => item.module_id === module)
              ?.build_nr
          }}
          <v-divider vertical class="mx-5 my-2" />
          <span class="fs-12 font-italic">{{ module }}</span>
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="!detailViewerRendered"
                icon
                v-bind="attrs"
                @click="searchObjectInModuleForModule(module)"
                v-on="on"
              >
                <v-icon dense> mdi-printer-3d </v-icon>
              </v-btn>
            </template>
            <span>{{
              $t('modules.BillOfMaterial.elementSchedule.show3D')
            }}</span>
          </v-tooltip>
        </div>
      </div>
      <forge-viewer
        ref="forge-viewer"
        class="mt-2"
        style="min-height: 250px"
        :ant-toolbar-options="{
          viewType: {
            display: false,
            enabled: false,
          },
          performanceMode: {
            display: true,
            enabled: true,
          },
          models: {
            display: false,
            enabled: false,
          },
          antTable: {
            display: false,
            enabled: false,
          },
          ghosting: {
            display: false,
            enabled: false,
          },
          modelTree: {
            display: false,
            enabled: false,
          },
          clearIsolation: {
            display: false,
            enabled: false,
          },
          objectProperties: {
            display: false,
            enabled: false,
          },
          sidebar: {
            display: false,
            enabled: false,
          },
        }"
        :ant-viewer-toolbar="false"
        :client="dhmeBillOfMaterialClient"
        :display-search="false"
        :extension-options="[]"
        :extensions="[]"
        :headless="true"
        :models="dhmeBillOfMaterialModels"
        @modelsRendered="viewerRendered"
      />
    </div>
  </div>
</template>

<script>
import DynamicDataTable from '../../../components/DynamicDataTable.vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
import ForgeViewer from '@/components/Modules/Forge/ForgeViewer.vue';
import { getObjectsWithMappingLevel } from '@/components/Modules/Daiwa-House-Modular-Europe/utils/DHME+utils';
import { executeCustomModuleCall } from '@/services/api/module.api';
import { DHME_BILL_OF_MATERIAL } from '@/modules/modules';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';

export default {
  name: 'DhmeBillOfMaterialObjects',
  components: { ForgeViewer, DynamicDataTable },
  props: {
    supplier: {
      type: Object,
      required: true,
    },
    tableTabState: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      assemblyStartDate: undefined,
      assemblyLocationWorkload: undefined,
      assemblyStartHour: undefined,
      tableLoading: false,
      tableRecords: [],
      tableHeaders: [],
      selectedCell: null,
      detailViewerRendered: false,
      selectedElement: null,
      elementCount: null,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'dhmeBillOfMaterialData',
      'dhmeBillOfMaterialResourceGroups',
      'dhmeBillOfMaterialModules',
      'dhmeBillOfMaterialNonModules',
      'dhmeBillOfMaterialPhases',
      'dhmeBillOfMaterialObjects',
      'dhmeBillOfMaterialElements',
      'dhmeBillOfMaterialResourceGroupObjects',
      'dhmeBillOfMaterialResourceGroupElements',
      'dhmeBillOfMaterialClient',
      'dhmeBillOfMaterialModels',
      'dhmeBillOfMaterialModelMapping',
      'dhmeBillOfMaterialModelMappings',
      'dhmeBillOfMaterialAssemblyTasks',
      'dhmeBillOfMaterialPlacementTasks',
      'dhmeBillOfMaterialAssemblyPhase',
    ]),
    objectSchedulePlacementHeaders() {
      let headers = [
        {
          text: this.text('table.objectType'),
          value: 'object_type',
        },
        {
          text: this.text('table.NLSFB'),
          value: 'NLSFB',
        },
        {
          text: this.text('table.total'),
          value: 'total',
          hasHeaderSlot: true,
        },
        {
          text: 'check',
          value: 'check',
          hasSlot: true,
        },
      ];

      this.dhmeBillOfMaterialPlacementTasks.forEach((task) => {
        headers.push({
          text: task.title,
          value: task.title,
          width: '100px',
          phase: true,
          name: task.title,
          hasHeaderSlot: true,
          hasSlot: true,
          sortable: false,
        });
      });

      return headers;
    },
    objectScheduleProductionHeaders() {
      let start = moment(this.dhmeBillOfMaterialAssemblyTasks[0].planned_start);
      let end = moment(
        this.dhmeBillOfMaterialAssemblyTasks[
          this.dhmeBillOfMaterialAssemblyTasks.length - 1
        ].planned_start
      );
      const assemblyWeeks = end.diff(start, 'weeks');

      let headers = [
        {
          text: this.text('table.objectType'),
          value: 'object_type',
        },
        {
          text: this.text('table.NLSFB'),
          value: 'NLSFB',
        },
        {
          text: this.text('table.total'),
          value: 'total',
          hasHeaderSlot: true,
        },
        {
          text: 'check',
          value: 'check',
          hasSlot: true,
        },
      ];
      for (let i = 0; i <= assemblyWeeks; i++) {
        const date = start.clone().add(i, 'week');
        let week = date.clone().isoWeek();
        headers.push({
          text: `PW${i + 1} | KW${week}`,
          value: `week_${week}`,
          width: '100px',
          week: week,
          week_start: moment(date).day('Monday').set({
            hour: this.assemblyStartHour,
            minute: 0,
            second: 0,
            millisecond: 0,
          }),
          week_end: moment(date)
            .day('Friday')
            .set({
              hour: this.assemblyStartHour + this.assemblyLocationWorkload,
              minute: 0,
              second: 0,
              millisecond: 0,
            }),
          date: date,
          hasHeaderSlot: true,
          hasSlot: true,
          sortable: false,
        });
      }

      return headers;
    },

    elements() {
      return this.dhmeBillOfMaterialResourceGroupElements
        .filter((mapping) => mapping.group_code === this.supplier.group_code)
        .map((mapping) => mapping.element_type)
        .sort((a, b) => a.localeCompare(b));
    },
    invalidData() {
      return this.dhmeBillOfMaterialObjects.filter((record) => {
        if (
          !record.module_id ||
          !record.element_id ||
          !record.element_type ||
          !record.object_type
        ) {
          return record;
        }
      });
    },
    forgeViewer() {
      return this.$refs['forge-viewer'].viewerService.Viewer3D;
    },
    forgeModelObjects() {
      return this.$refs['forge-viewer'].modelObjects.flatMap(
        (item) => item.properties
      );
    },
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_BILL_OF_MATERIAL
      ).id;
    },
  },
  watch: {
    supplier(value) {
      switch (this.tableTabState) {
        case 'production':
          this.calculateProductionSchedule();
          break;
        case 'placement':
          this.calculatePlacementSchedule();
          break;
      }
    },
    async selectedElement(value) {
      let { elements } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ELEMENTS',
            project: this.project.id,
            as: 'elements',
            columns: [
              {
                name: 'element_type',
                conditions: [
                  {
                    operator: '=',
                    value: this.selectedElement,
                  },
                ],
              },
            ],
          },
        ],
      });

      this.elementCount = elements.records.length;
      switch (this.tableTabState) {
        case 'production':
          this.calculateProductionSchedule();
          break;
        case 'placement':
          this.calculatePlacementSchedule();
          break;
      }
    },
    tableTabState(value) {
      switch (this.tableTabState) {
        case 'production':
          this.tableHeaders = this.objectScheduleProductionHeaders;
          this.calculateProductionSchedule();
          break;
        case 'placement':
          this.tableHeaders = this.objectSchedulePlacementHeaders;
          this.calculatePlacementSchedule();
          break;
      }
    },
  },
  mounted() {
    if (this.dhmeBillOfMaterialAssemblyPhase.length === 1) {
      this.assemblyStartHour =
        this.dhmeBillOfMaterialAssemblyPhase[0].task_type.custom_5;
      this.assemblyLocationWorkload =
        this.dhmeBillOfMaterialAssemblyPhase[0].task_type.custom_6;

      this.assemblyStartDate = moment(
        this.dhmeBillOfMaterialAssemblyPhase[0].planned_start
      ).set({
        hour: this.assemblyStartHour,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      this.calculateProductionSchedule();
      this.tableHeaders = this.objectScheduleProductionHeaders;
    } else {
      this.$store.commit('showNotification', {
        content: 'Assembly phase not found',
        color: 'warning',
      });
    }
  },
  methods: {
    exportSpecificationPerElement() {
      this.tableLoading = true;
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'generateSpecificationPerElement',
        {
          group: this.supplier,
          weeks: this.objectScheduleProductionHeaders
            .filter((header) => {
              return header.week;
            })
            .map((week) => {
              return {
                week: week.week,
                week_start: week.week_start.format('YYYY-MM-DD HH:mm:ss'),
                week_end: week.week_end.format('YYYY-MM-DD HH:mm:ss'),
              };
            }),
        }
      ).then((response) => {
        const element = document.createElement('a');
        element.setAttribute('href', `data:xlsx;base64,${response.file}`);
        element.setAttribute(
          'download',
          `Specificatie_${this.supplier.name}_per_onderdeel_${moment().format(
            'YYYY-MM-DD'
          )}.xlsx`
        );

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
        this.tableLoading = false;
      });
    },
    exportSpecification() {
      this.tableLoading = true;
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'generateSpecification',
        {
          objects: this.tableRecords,
          group: this.supplier,
        }
      ).then((response) => {
        const element = document.createElement('a');
        element.setAttribute('href', `data:xlsx;base64,${response.file}`);
        element.setAttribute(
          'download',
          `Specificatie_${this.supplier.name}_${moment().format(
            'YYYY-MM-DD'
          )}.xlsx`
        );

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
        this.tableLoading = false;
      });
    },
    text() {
      const [path, ...params] = arguments;
      return this.$t(`modules.BillOfMaterial.objectSchedule.${path}`, params);
    },
    checkObjectTypeCount(item) {
      return (item.total / this.elementCount).toFixed(2);
    },
    viewerRendered(value) {
      this.$refs['forge-viewer'].setExternalMapping(value.myData.urn);
      this.$refs['forge-viewer'].viewerService.setViewTo('top back right');
      document.getElementById('guiviewer3d-toolbar').style.visibility =
        'hidden';
      this.$refs['forge-viewer'].performanceModeToggle = true;
      this.detailViewerRendered = true;
    },
    calculatePlacementSchedule() {
      this.tableRecords = [];
      this.tableLoading = true;

      const body = {
        project: {
          id: this.project.id,
        },
        group: this.supplier.group_code,
        element: this.selectedElement,
        phases: this.dhmeBillOfMaterialPlacementTasks.map((task) => {
          return {
            id: task.id,
            name: task.title,
          };
        }),
      };
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'getGroupObjectPlacementSchedule',
        body
      ).then((response) => {
        response.forEach((item) => {
          item.total = 0;
          this.dhmeBillOfMaterialPlacementTasks.forEach((task) => {
            item.total += item[task.title].count;
          });
        });
        this.tableRecords = response;
        this.tableLoading = false;
      });
    },
    calculateProductionSchedule() {
      this.tableRecords = [];
      this.tableLoading = true;

      const body = {
        project: {
          id: this.project.id,
        },
        group: this.supplier.group_code,
        element: this.selectedElement,
        weeks: this.objectScheduleProductionHeaders
          .filter((header) => {
            return header.week;
          })
          .map((week) => {
            return {
              week: week.week,
              week_start: week.week_start.format('YYYY-MM-DD HH:mm:ss'),
              week_end: week.week_end.format('YYYY-MM-DD HH:mm:ss'),
            };
          }),
      };
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'getGroupObjectProductionSchedule',
        body
      ).then((response) => {
        if (response.length > 0) {
          let weeks = Object.keys(response[0]).filter((key) =>
            key.startsWith('week_')
          );
          response.forEach((item) => {
            item.total = 0;
            weeks.forEach((week) => {
              item.total += item[week].count;
            });
          });
        }
        this.tableRecords = response;
        this.tableLoading = false;
      });
    },
    clickedCell(item, elementType, header) {
      item.object_type = elementType;
      item.header = header;
      this.selectedCell = item;
    },
    searchObjectInModuleForModule(item) {
      this.dhmeBillOfMaterialModels
        .filter((m) => m.enabled)
        .forEach((model) => {
          const mapping = this.dhmeBillOfMaterialModelMappings.find(
            (mapping) => mapping.model === model.id
          );

          let modelObjects = this.$refs['forge-viewer'].modelObjects.find(
            (item) => item.urn === model.urn
          ).properties;

          const moduleIdMappingLevels = mapping.module_id.split('.');
          const objectTypeMappingLevels = mapping.object_type.split('.');
          let moduleObjects = getObjectsWithMappingLevel(
            modelObjects,
            moduleIdMappingLevels
          );
          let tmp = getObjectsWithMappingLevel(
            moduleObjects,
            objectTypeMappingLevels
          );

          let objects = tmp.filter(
            (object) =>
              moduleIdMappingLevels.reduce(
                (o, i) => o[i],
                object.properties
              ) === item &&
              objectTypeMappingLevels.reduce(
                (o, i) => o[i],
                object.properties
              ) === this.selectedCell.object_type
          );

          if (objects.length > 0) {
            let objectIds = this.$refs[
              'forge-viewer'
            ].mapExternalIdsToObjectIdsMultiModel(
              model.urn,
              objects.map((object) => object.externalId)
            );
            this.forgeViewer.isolate(objectIds);
            this.forgeViewer.fitToView(objectIds);
          } else {
            this.$store.commit('showNotification', {
              content: `Objects not found in model for module ${item} and object type ${this.selectedCell.object_type}`,
              color: 'warning',
            });
          }
        });
    },
    getHeaderTotal(header) {
      let sum = 0;
      this.tableRecords.forEach((record) => {
        if (header.value.startsWith('week_')) {
          sum += record[header.value].count;
        } else {
          sum += record.total;
        }
      });
      return sum;
    },
  },
};
</script>
<style lang="scss" scoped>
.supplier-header {
  font-size: 18px;
  font-weight: 500;
}

.supplier-sub-header {
  font-style: italic;
}

.clickable-cell {
  transition: 200ms;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
